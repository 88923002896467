import styled from 'styled-components';
import { media } from '../utils/styles';

export default styled.h2`
  font-family: ${props => props.theme.headerFontFamily};
  font-size: 33px;
  line-height: 45px;
  font-weight: 600;
  letter-spacing: 0.065em;
  color: #4d4d4d;
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto;
  padding: 10px 0;

  ${media.tablet`
    font-size: 40px;
    font-weight: 400;
  `} ${media.desktop`
    font-size: 61px;
    line-height: 61px;
    max-width: 700px;
  `};
`;
