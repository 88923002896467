import React from 'react';
import styled from 'styled-components';
import theme from '../../utils/theme';
import PaginationLink from './PaginationLink';

const Wrapper = styled.ul`
  text-align: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const LinkHolder = styled.li`
  padding: 0;
  display: inline-block;
`;

const StyledLink = styled(PaginationLink)`
  font: 600 14px/23px 'proxima-soft', Helvetica, Arial, sans-serif;
  color: #656565;
  letter-spacing: 0.16em;
  margin: 0 10px 5px 10px;
  text-decoration: none;
  border-bottom: ${props =>
    props.disabled ? `1px solid ${theme.primary}` : 'none'};
  color: ${props => (props.disabled ? theme.primary : '#656565')};

  &:hover {
    color: #999;
    border-bottom: 1px solid #999;
  }
`;

export default ({ prev, next, pages, page }) => {
  const pageLinks = [];
  for (var index = 0; index < pages; index++) {
    pageLinks.push(
      <LinkHolder key={`pager_${index}`}>
        <StyledLink
          to={`/articles/${index === 0 ? '' : index + 1}`}
          text={index + 1}
          disabled={index === page - 1}
        />
      </LinkHolder>
    );
  }
  return (
    <Wrapper>
      <LinkHolder>
        <StyledLink to={prev} text="<" />
      </LinkHolder>
      {pageLinks}
      <LinkHolder>
        <StyledLink to={next} text=">" />
      </LinkHolder>
    </Wrapper>
  );
};
