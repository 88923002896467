import React from 'react';
import styled from 'styled-components';
import ArticlePreview from '../ArticlePreview';
import Heading from '../../elements/Heading';
import { media } from '../../utils/styles';
import Img from 'gatsby-image';

const ArticleList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
`;

const ListingHeading = styled(Heading)`
  color: #fff;
  font-size: 23px;
  margin-bottom: 30px;

  ${media.tablet`
    font-size: 50px;
    margin-bottom: 0;
  `};
`;

const ListingSubHeading = styled.span`
  text-align: center;
  font-family: ${props => props.theme.headerFontFamily};
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  background-color: #37b7ea;
  padding: 10px 20px;
  border-radius: 20px;
  margin: 10px auto 20px;
  display: inline-block;
  line-height: 25px;
  font-weight: 600;
  letter-spacing: 0;

  ${media.tablet`
    font-size: 14px;
  `};
`;

const Wrapper = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 40px;
  margin-bottom: -30px;
  text-align: center;
  z-index: 1;
  margin-top: -135px;
  position: relative;
  height: 250px;

  ${media.tablet`
    height: 220px;
    margin-top: -220px;
    padding-top: 50px;
    margin-bottom: 40px;
  `} ${media.smallDesktop`
    height: 288px;
    margin-top: -288px;
    padding-top: 50px;
  `};
`;

export default ({ heading, coverImage, subHeading, articles }) => (
  <>
    <Img
      alt={subHeading}
      fluid={coverImage.fluid}
    />
    <Wrapper>
      <ListingHeading>{heading}</ListingHeading>
      <ListingSubHeading>{subHeading}</ListingSubHeading>
    </Wrapper>
    <ArticleList>
      {articles.map(({ node: article }) => (
        <ArticlePreview key={article.id} article={article} />
      ))}
    </ArticleList>
  </>
);