import React from 'react';
import Link from 'gatsby-link';

export default ({ className, to, text, disabled }) => {
  if (to && text && !disabled) {
    return (
      <Link to={to} className={className}>
        {text}
      </Link>
    );
  }
  return <span className={className}>{text}</span>;
};
