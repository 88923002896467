import React from 'react';
import Section from '../../elements/Section';
import Wrapper from '../../elements/Wrapper';
import Pager from '../Pager';
import ArticleList from '../ArticleList';

export default ({ coverImage, heading, subHeading, articles, next, prev, pages, page }) => (
  <Section>
    <Wrapper header={true}>
      <ArticleList
        coverImage={coverImage}
        heading={heading}
        subHeading={subHeading}
        articles={articles}
      />
      <Pager next={next} prev={prev} pages={pages} page={page} />
    </Wrapper>
  </Section>
);