import React from 'react';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import ArticlesTemplate from '../components/ArticlesTemplate';
import Seo from '../components/Seo';

export default ({
  pageContext: { nodes: articles, page, prev, next, pages, total },
  data: { contentfulArticleListingPage: pageInfo },
  location: { href }
}) => <Layout canonicalUrl={href}>
    <Seo
      title={pageInfo.metaData.title}
      url={href}
      description={pageInfo.metaData.description}
    />
    <ArticlesTemplate
      heading={pageInfo.heading}
      subHeading={pageInfo.subHeading}
      coverImage={pageInfo.coverImage}
      articles={articles}
      next={next}
      prev={prev}
      pages={pages}
      page={page}
    />
  </Layout>;

export const articlesPageQuery = graphql`
  query {
    contentfulArticleListingPage {
      heading
      subHeading
      metaData {
        title
        description
      }
      coverImage {
        title
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;
